import { QueryClient, useMutation } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { ITINERAY_CONSTANTS } from './ItineraryApi';

export const MUTATIONS = {
  CREATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, formData }: {
      eventId: string;
      dayId: string;
      formData: FormData
    }) => {
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/activity`, formData);
    },
    onSuccess: (data: any, variables) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  UPDATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, payload }: {
      eventId: string;
      dayId: string;
      activityId: string;
      payload: any
    }) => {
      return HttpSrvc.put(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`, payload);
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),

  UPLOAD_ACTIVITY_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, file }: {
      eventId: string;
      dayId: string;
      activityId: string;
      file: File
    }) => {
      const formData = new FormData();
      formData.append('file', file);
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}/image`, formData);
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  }),
  DELETE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId }: {
      eventId: string;
      dayId: string;
      activityId: string
    }) => {
      return HttpSrvc.delete(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`);
    },
    onSuccess: (data: any, variables: any) => {
      queryClient?.invalidateQueries({ queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, variables.eventId] });
    }
  })
}