import React, { useState, useRef } from 'react';
import { X } from 'lucide-react';
import { ImagePreview } from './ImagePreview';
import VideoPreview from './VideoPreview';

interface FileUploaderProps {
  type: 'image' | 'video' | null;
  onClose: (e: any) => void;
  onSelect: (file: File | null) => void;
  selectedFile: File | null,
  setSelectedFile: (file: File | null) => void;
  uploadingInProgress?: boolean;
}

export function FileUploader({ type, onClose, onSelect, uploadingInProgress, selectedFile, setSelectedFile }: FileUploaderProps) {
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const acceptedTypes = type === 'image' ? 'image/*' : 'video/*';

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadingInProgress) {
      return;
    }

    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      onSelect(file);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (uploadingInProgress) {
      return;
    }
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith(type === 'image' ? 'image/' : 'video/')) {
      setSelectedFile(file);
      onSelect(file);
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    if (uploadingInProgress) {
      return;
    }
  };

  const removeFile = () => {
    if (uploadingInProgress) {
      return;
    }
    setSelectedFile(null);
    onSelect(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div
      className="border-2 border-dashed border-[#6B6C6E]/40 rounded-lg p-6 mb-3 relative"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <button
        onClick={(e) => { removeFile(); onClose(e) }}
        className="absolute top-2 right-2 text-[#6B6C6E] hover:text-white"
      >
        <X className="w-5 h-5" />
      </button>

      {selectedFile ? (
        type === 'image' ? (
          <ImagePreview file={selectedFile} onRemove={removeFile} />
        ) : (
          <VideoPreview file={selectedFile} onRemove={removeFile} />
        )
      ) : (
        <div className="text-center">
          <input
            ref={inputRef}
            type="file"
            accept={acceptedTypes}
            onChange={handleFileChange}
            className="hidden"
            id="file-upload"
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer text-[#93c5fd] hover:text-white"
          >
            <span>Upload a {type}</span>
            <p className="mt-1 text-sm text-[#6B6C6E]">
              Drag and drop or click to select
            </p>
          </label>
        </div>
      )}
    </div>
  );
}