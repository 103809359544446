import React, { useState, useRef, useContext, useEffect } from 'react';
import { BookCopy, EllipsisVertical, MoreVertical, Trash2 } from 'lucide-react';
import { useClickOutside } from '../hooks/hooks';
import { CurrentUserContext, ToastContext } from '../Contexts';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import ModalWrapper from '../Modal';
import moment from 'moment';

interface FeedMenuProps {
  event: any;
  className?: string;
}

export function EventOptionsMenu({ event, className }: FeedMenuProps) {
  const queryClient = useQueryClient();
  const modalRef: any = useRef();

  const openModal = () => {
    modalRef?.current?.openModal();
  };

  const closeModal = () => {
    modalRef?.current?.closeModal();
  };

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useContext(CurrentUserContext);

  const toast = useContext(ToastContext);

  const deleteEventMutation = QuerySrvc.MUTATIONS.EVENT.DELETE_EVENT(queryClient);

  const cloneEventMutation = QuerySrvc.MUTATIONS.EVENT.CLONE_EVENT(queryClient);

  const isEventOwnerSameAsCurrentUser = event?.owner?.guid === currentUser?.guid;

  useClickOutside(menuRef, (e) => { setIsOpen(false); e.stopPropagation(); });

  const [cloneEventStartTime, setCloneEventStartTime] = useState<string>(moment.utc(event.startTime).toISOString());

  useEffect(() => {
    if (cloneEventMutation.status === 'success') {
      toast?.showSuccessToast('Event cloned successfully');
      closeModal();
    } else if (cloneEventMutation.status === 'error') {
      toast?.showErrorToast('Failed to clone event');
    }
  }, [cloneEventMutation.status]);

  useEffect(() => {
    if (deleteEventMutation.status === 'success') {
      toast?.showSuccessToast('Event deleted successfully');
    } else if (deleteEventMutation.status === 'error') {
      toast?.showErrorToast('Failed to delete event');
    }
  }, [deleteEventMutation.status]);

  if (!isEventOwnerSameAsCurrentUser) {
    return null;
  }

  return (
    <div ref={menuRef} className={`${className} absolute `}>
      <div
        className="absolute top-0 right-0 p-2 shadow-xl bg-brand-lightgray rounded-full cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }} >
        <EllipsisVertical size={16} className="text-brand-darkgray" />
      </div>

      {
        isOpen && (
          <div className="absolute flex flex-col gap-y-2 right-0 top-10 mt-1 w-48  z-10 ">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
                openModal();
              }}
              className="w-full px-4 py-2 text-left bg-brand-lightgray text-brand-darkgray flex items-center gap-2 rounded-md shadow-lg border-2 border-brand-lightgraybackup/10"
            >
              <BookCopy className="w-4 h-4" />
              Clone
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
                if (window.confirm('Are you sure you want to delete this event? This cannot be undone')) {
                  deleteEventMutation.mutate(event?.id);
                }
              }}
              className="w-full px-4 py-2 text-left bg-brand-lightgray text-brand-darkgray flex items-center gap-2 rounded-md shadow-lg border-2 border-brand-lightgraybackup/10"
            >
              <Trash2 className="w-4 h-4" />
              Delete
            </button>

          </div>
        )
      }

      <ModalWrapper ref={modalRef} title={`Clone Event: ${event.name} | ${moment.utc(event.startTime).format("MMM DD")} - ${moment.utc(event.endTime).format("MMM DD, YYYY")}`}
        actionButton={<button
          disabled={cloneEventMutation.isPending}
          onClick={() => {
            cloneEventMutation.mutate({ eventId: event.id, startDateTime: cloneEventStartTime });
          }}
          className="btn flex flex-1 flex-row w-28 btn-brand-lightgray" >
          Clone 
          {
            cloneEventMutation.isPending && <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-fadegray"></div>
          }
        </button>}>

        <div className="relative text-brand-lightgray mt-4 mb-2">Select New Start Date</div>
        <input
          type="date"
          name="startIsoTime"
          disabled={cloneEventMutation.isPending}
          defaultValue={moment.utc(event.startTime).format("YYYY-MM-DD")}
          min={moment.utc(event.startTime).format("YYYY-MM-DD")}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          onChange={(e) => {
            e.preventDefault();
            setCloneEventStartTime((new Date(e.target.value)).toISOString());
          }}
          className={`countdown font-mono font-bold mt-1 bg-brand-darkgray border-2 
          ${cloneEventMutation.isPending ? "border-brand-lightgraybackup" : "border-brand-lightgray"} text-brand-lightgray p-2 rounded-md`}
        />
      </ModalWrapper>
    </div>
  );
}