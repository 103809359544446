import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { PlusCircle, Edit, Trash2, Save, X, Star, Eye, EyeOff } from 'lucide-react';
import { Form, FeedbackFormField } from './types';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastContext } from '../Contexts';
import { getErrorStringFromObject } from '../../services/utils';

const initialFormState: Form = {
  title: 'Feedback Form',
  description: 'Please provide your feedback for the below experiences, by rating them on a scale of 1(POOR) to 5(EXCELLENT)',
  fields: [],
};

export default function FeedbackManager({ eventId }: any) {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [currentForm, setCurrentForm] = useState<Form>(initialFormState);
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);
  const [newField, setNewField] = useState('');
  const toast = useContext(ToastContext);
  const { data: forms = [], isLoading } = QuerySrvc.QUERIES.FEEDBACK.GET_FEEDBACK_FORMS(eventId);

  const { data: formResponse, isFetching: isFetchingFormResponse } = QuerySrvc.QUERIES.FEEDBACK.GET_FEEDBACK_FORM_RESPONSES(eventId, forms[0]?.id);

  const createMutation = QuerySrvc.MUTATIONS.FEEDBACK.CREATE_FEEDBACK_FORM(queryClient);
  const updateMutation = QuerySrvc.MUTATIONS.FEEDBACK.UPDATE_FEEDBACK_FORM(queryClient);
  const deleteMutation = QuerySrvc.MUTATIONS.FEEDBACK.DELETE_FEEDBACK_FORM(queryClient);
  const publishMutation = QuerySrvc.MUTATIONS.FEEDBACK.UPDATE_FEEDBACK_FORM(queryClient);

  useEffect(() => {
    if (forms.length > 0 && !selectedFormId) {
      setSelectedFormId(forms[0].id);
      setCurrentForm(forms[0]);

    }
  }, [forms, selectedFormId]);

  useEffect(() => {
    if (createMutation.status === 'success') {
      toast?.showSuccessToast('Form created successfully');
      resetForm();
    } else if (createMutation.status === 'error') {
      toast?.showErrorToast(getErrorStringFromObject(createMutation.error));
    }
  }, [createMutation.status]);

  useEffect(() => {
    if (updateMutation.status === 'success') {
      toast?.showSuccessToast('Form updated successfully');
      resetForm();
    } else if (updateMutation.status === 'error') {
      toast?.showErrorToast(getErrorStringFromObject(updateMutation.error));
    }
  }, [updateMutation.status]);

  useEffect(() => {
    if (deleteMutation.status === 'success') {
      toast?.showSuccessToast('Form deleted successfully');
      resetForm();
    } else if (deleteMutation.status === 'error') {
      toast?.showErrorToast('Error deleting form');
      toast?.showErrorToast(getErrorStringFromObject(deleteMutation.error));
    }
  }, [deleteMutation.status]);

  useEffect(() => {
    if (publishMutation.status === 'success') {
      toast?.showSuccessToast('Form published successfully');
      resetForm();
    } else if (publishMutation.status === 'error') {
      toast?.showErrorToast('Error publishing form');
      toast?.showErrorToast(getErrorStringFromObject(publishMutation.error));
    }
  }, [publishMutation.status]);

  const resetForm = () => {
    setCurrentForm(initialFormState);
    setIsEditing(false);
    setSelectedFormId(null);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (currentForm?.id) {
      updateMutation.mutate({ eventId, formId: currentForm?.id, update: currentForm });
    } else {
      createMutation.mutate({ eventId, currentForm });
    }
  };

  const handleAddField = () => {
    if (newField.trim()) {
      setCurrentForm({
        ...currentForm,
        fields: [...currentForm?.fields, { label: newField.trim() }],
      });
      setNewField('');
    }
  };

  const handleRemoveField = (index: number) => {
    setCurrentForm({
      ...currentForm,
      fields: currentForm?.fields?.filter((_, i) => i !== index),
    });
  };

  const handlePublishToggle = (form: Form) => {
    publishMutation.mutate({
      eventId,
      formId: form.id,
      update: {
        "isActive": true
      }
    });
  };


  const renderFormList = () => (
    <div className="w-64 border-r border-brand-fadegray p-4">
      <h2 className="text-lg font-semibold mb-4 text-brand-lightgray">Forms</h2>
      <div className="space-y-2">
        {forms.map((form: Form) => (
          <div
            key={form.id}
            className={`p-3 rounded-lg cursor-pointer transition-colors ${selectedFormId === form.id ? 'bg-brand-fadegray/30' : 'hover:bg-brand-fadegray/20'
              }`}
            onClick={() => {
              if (form?.id) {
                setSelectedFormId(form?.id);
                setCurrentForm(form);
                setIsEditing(false);
              }

            }}
          >
            <div className="flex justify-between items-center">
              <span className="text-brand-lightgray">{form.title}</span>
              <div className="flex gap-2">
                {/* {form.published ? (
                  <Eye className="w-4 h-4 text-brand-midgreen" />
                ) : (
                  <EyeOff className="w-4 h-4 text-brand-lightgraybackup" />
                )} */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderFormDetails = () => {
    if (isEditing) {
      return (
        <form onSubmit={handleSubmit} className="flex flex-1 flex-col w-1/2 space-y-4 border-2 border-brand-fadegray sm:p-8 p-4 rounded-lg">
          <div>
            <label className="block text-sm font-medium mb-1">Title</label>
            <input
              type="text"
              placeholder='Name the form'
              value={currentForm?.title}
              onChange={(e) => setCurrentForm({ ...currentForm, title: e.target.value })}
              className="w-full bg-brand-darkgray border border-brand-fadegray rounded-lg px-3 py-2 text-brand-lightgray"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <textarea
              value={currentForm?.description}
              placeholder='Please provide your feedback for the below experiences, by rating them on a scale of 1(POOR) to 5(EXCELLENT)'
              onChange={(e) => setCurrentForm({ ...currentForm, description: e.target.value })}
              className="w-full bg-brand-darkgray border border-brand-fadegray rounded-lg px-3 py-2 text-brand-lightgray"
              rows={3}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Fields to Rate</label>
            <div className="flex gap-2 mb-2">
              <input
                type="text"
                value={newField}
                onChange={(e) => setNewField(e.target.value)}
                className="flex-1 bg-brand-darkgray border border-brand-fadegray rounded-lg px-3 py-2 text-brand-lightgray"
                placeholder={`Enter parameter to take rating`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddField();
                  }
                }}
              />
              <button
                type="button"
                onClick={handleAddField}
                className="dark-btn"
              >
                Add Field
              </button>
            </div>

            <div className="space-y-2">
              {currentForm?.fields?.map((field, index) => (
                <div key={index} className="flex items-center justify-between bg-brand-darkgray p-2 rounded-lg border border-brand-fadegray">
                  <span className="text-brand-lightgray">{(index + 1)}. {field.label}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveField(index)}
                    className="text-brand-danger hover:text-red-600 transition-colors"
                  >
                    <X size={18} />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-4">
            <button
              type="button"
              onClick={resetForm}
              className="px-4 py-2 border border-brand-fadegray text-brand-lightgray rounded-lg hover:bg-brand-fadegray/20"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="dark-btn flex"
            >
              <Save size={18} />
              Save Form
            </button>
          </div>
        </form>
      );
    }

    const selectedForm = forms.find((f: Form) => f.id === selectedFormId);

    if (!selectedForm) {
      return null;
    }

    return (
      <div className="space-y-6">


        <div className="grid grid-cols-1 gap-4 border-brand-fadegray border-2 p-4">
          <div className="flex justify-between items-start">
            <div className="flex flex-1 flex-col gap-4">
              <h2 className="text-xl font-semibold text-brand-lightgray">
                {selectedForm.title}
                {selectedForm.isActive ?
                  <span className='text-sm text-brand-midgreen'> (Published) </span> :
                  <span className='text-sm text-brand-lightgraybackup'> (Draft) </span>
                }
              </h2>
              <p className="text-brand-lightgraybackup ">Description : {selectedForm.description}</p>
            </div>
            <div className="flex flex-1 items-center justify-end flex-row gap-2">
              {
                !selectedForm?.isActive && <button
                  onClick={() => handlePublishToggle(selectedForm)}
                  className={`dark-btn`}
                >
                  <Eye size={18} /> Publish
                </button>
              }

              <button
                onClick={() => {
                  if (selectedForm.id && confirm('Are you sure you want to delete this form?')) {
                    deleteMutation.mutate({ eventId, formId: selectedForm.id });
                  }
                }}
                className="p-2 text-brand-danger hover:text-red-600 transition-colors"
              >
                <Trash2 size={18} />
              </button>
            </div>
          </div>
          <div className='text-sm'>
            Total Responses Submitted : {formResponse?.responsesCount}
            <br />
            Total Average : {formResponse?.totalAverage || 0}
          </div>
          {formResponse?.responses.map((field: any, index: any) => (
            <div className='flex flex-col gap-2' key={index}>
              <div className='flex flex-1 flex-row text-md text-brand-lightgray justify-between pr-4'>
                <div>{index + 1}. {field.label}</div>
                <div>Average Score : {field?.averageRating || 0}</div>
              </div>
              <progress className="progress w-full h-4" value={field?.averageRating || 0} max={5}>
              </progress>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div className="text-center py-8">Loading forms...</div>;
  }


  return (
    <div className="flex flex-1 h-full bg-brand-darkgray">
      {/* {renderFormList()} */}
      <div className={`flex flex-1 flex-col ${currentForm ? 'justify-start' : 'justify-center'} items-center sm:p-8`}>
        {!isEditing && !selectedFormId && (<div className="flex flex-1 flex-col justify-center items-center mb-6">

          <p className='mb-4 text-center text-brand-lightgraybackup'>(Optional) Create a feedback form for your guests.
            <br />
            <span className='text-brand-lightgraybackup'>You can publish the feedback form when ready and start taking the user feedback.</span>
          </p>
          <button
            onClick={() => {
              setCurrentForm(initialFormState);
              setIsEditing(true);
              setSelectedFormId(null);
            }}
            className="flex dark-btn mx-auto"
          >
            <PlusCircle size={20} />
            Create New Form
          </button>
        </div>
        )}

        {renderFormDetails()}
      </div>
    </div>
  );
}