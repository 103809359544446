import axios from 'axios';
import HttpSrvc from '../HttpSrvc';
import { useQuery } from '@tanstack/react-query';

export const QUERIES = {
  GET: (itineraryId: string | null | undefined) => useQuery({
    staleTime: 10000,
    queryKey: [ITINERAY_CONSTANTS.QUERY_KEYS.ITINERARY_DATA, itineraryId],
    queryFn: () => {
      return HttpSrvc.get(`${ITINERAY_CONSTANTS.ITINERARY_API}/${itineraryId}`);
    },
    enabled: !!itineraryId
  })
};

export const MUTATIONS = {};

export const ITINERAY_CONSTANTS = {
  QUERY_KEYS: {
    ITINERARY_DATA: 'ITINERARY_DATA'
  },
  ITINERARY_API: '/oapi/itinerary'
}