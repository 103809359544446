import React, { useState } from 'react';
import { FileText, Image } from 'lucide-react';

interface Document {
  id: string;
  type: string;
  mimeType: string;
  document: {
    original: string;
  };
  otherDocumentType?: string;
  userId: string;
  event: string;
  thirdPartyUploader: {
    guid: string;
    firstName: string;
    lastName: string;
    profilePic: string;
  };
}

interface DocumentCardProps {
  document: Document;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ document }) => {
  const [thumbnailError, setThumbnailError] = useState(false);

  const getDocumentIcon = (mimeType: string) => {
    if (mimeType.startsWith('image/')) {
      return <Image className="w-6 h-6" />;
    }
    return <FileText className="w-6 h-6" />;
  };

  const formatDocumentType = (type: string, otherDocumentType?: string) => {
    if (type === 'other' && otherDocumentType) {
      return otherDocumentType;
    }
    return type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const renderThumbnail = () => {
    if (thumbnailError) {
      return (
        <div className="w-20 h-20 bg-brand-fadegray/30 rounded flex items-center justify-center">
          <FileText className="w-8 h-8 text-brand-lightgraybackup" />
        </div>
      );
    }

    if (document.mimeType.startsWith('image/')) {
      return (
        <div className="relative w-20 h-20 rounded flex items-center justify-center canvas-bg">
          <img
            src={document.document.original}
            alt="Document preview"
            className="w-20 h-20 object-contain rounded"
            onError={() => setThumbnailError(true)}
          />
        </div>
      );
    }

    if (document.mimeType === 'application/pdf') {
      // For PDFs, we could potentially use a PDF.js thumbnail in the future
      return (
        <div className="relative w-20 h-20 bg-white rounded flex items-center justify-center">
          <FileText className="w-8 h-8 text-red-500" />
          <span className="text-xs text-red-500 font-bold absolute bottom-2">PDF</span>
        </div>
      );
    }

    return (
      <div className="w-20 h-20 bg-brand-fadegray/30 rounded flex items-center justify-center">
        <FileText className="w-8 h-8 text-brand-lightgraybackup" />
      </div>
    );
  };

  return (
    <div
      className="bg-brand-fadegray/20 rounded-lg p-4 hover:bg-brand-fadegray/30 transition-colors cursor-pointer"
      onClick={() => window.open(document.document.original, '_blank')}
    >
      <div className="flex items-start gap-4">
        <div className="flex flex-col flex-1 min-w-0">
          <div className="flex items-start gap-3 mb-2">
            {getDocumentIcon(document.mimeType)}
            <div className="flex-1 min-w-0">
              <p className="text-brand-lightgray text-sm font-medium truncate">
                {formatDocumentType(document.type, document.otherDocumentType)}
              </p>
              <p className="text-brand-lightgraybackup text-xs">
                {document.mimeType.split('/')[1].toUpperCase()}
              </p>
            </div>
          </div>
          <div className="flex items-end gap-2 mt-4">
            <p className="text-brand-lightgraybackup text-xs">
              Uploaded by {document.thirdPartyUploader.firstName} {document.thirdPartyUploader.lastName}
            </p>
          </div>
        </div>
        
        {/* Thumbnail Preview */}
        <div className="flex-shrink-0">
          {renderThumbnail()}
        </div>
      </div>
    </div>
  );
};

export default DocumentCard;