import { QueryClient, QueryKey, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { delay } from '../utils';

const updateInifiniteQueryList = (queryClient: QueryClient, queryKey: QueryKey, listKey: string, object: any) => {
  queryClient?.setQueryData(queryKey, (oldData: any) => {
    const clone = { ...oldData };
    clone.pages = clone.pages.map(((page: any) => {
      return {
        ...page,
        [listKey]: page[listKey]?.map((item: any) => {
          if (item.id === object.id) {
            return object;
          }
          return item;
        }) || []
      }
    }))
    return clone;
  });
}

export const QUERIES = {
  FEEDS: {
    GET: (eventId: string, paginationLimit: number) => useInfiniteQuery({
      staleTime: 10000,
      queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, eventId],
      queryFn: ({ pageParam }: any) => {
        const queryParams = {
          page: pageParam.page,
          limit: pageParam.limit
        };
        return HttpSrvc.get(`${FEED_CONSTANTS.FEEDS_API}/${eventId}`, queryParams)
      },
      initialPageParam: { page: 1, limit: paginationLimit },
      getNextPageParam: (lastPage: any, pages: any) => {
        if (lastPage.page < lastPage.totalPages) {
          return { page: lastPage.page + 1, limit: paginationLimit };
        }
        return undefined;
      },
      select: (data: any) => {
        return data.pages?.reduce((acc: any, page: any) => {
          return {
            feeds: [...acc.feeds, ...page.feeds],
            page: page.page,
            totalPages: page.totalPages
          }
        }, {
          feeds: [],
          page: 1,
          totalPages: 0
        });
      }
    }),
    GET_BY_ID: (eventId: string, feedId: string, enabled: boolean, queryClient?: QueryClient) => useQuery({
      staleTime: 5000,
      queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, eventId, feedId],
      queryFn: () => {
        return HttpSrvc.get(`${FEED_CONSTANTS.FEEDS_API}/${eventId}/${feedId}`)
      },
      placeholderData: () => {
        try {
          return (queryClient?.getQueryData([FEED_CONSTANTS.QUERY_KEYS.FEEDS, eventId]) as any)?.pages.map((page: any) => page.feeds).flat().find((feed: any) => feed.id === feedId) || undefined;
        } catch (error) {
          return undefined;
        }
      },
      enabled: enabled
    })
  },
  COMMENTS: {
    GET: (eventId: string, feedId: string, enabled: boolean, queryClient?: QueryClient) => useQuery({
      queryKey: [FEED_CONSTANTS.QUERY_KEYS.COMMENTS, eventId, feedId],
      queryFn: () => {
        return HttpSrvc.get(`${FEED_CONSTANTS.FEEDS_API}/${eventId}/${feedId}/comment`)
      },
      placeholderData: () => {
        try {
          return (queryClient?.getQueryData([FEED_CONSTANTS.QUERY_KEYS.COMMENTS, eventId, feedId]) as any) || undefined;
        } catch (error) {
          return undefined;
        }
      },
      enabled: enabled
    })
  },
};

export const MUTATIONS = {
  FEEDS: {
    POST: (queryClient?: QueryClient) => useMutation({
      mutationFn: ({ formData, eventId }: any) => {
        return HttpSrvc.postFormData(`${FEED_CONSTANTS.FEEDS_API}/${eventId}`, formData)
      },
      onMutate: (variables) => {
        queryClient?.cancelQueries({ queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId] });
      },
      onSuccess: (response, variables) => {
        queryClient?.invalidateQueries({ queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId] });
      }
    }),
    DELETE: (queryClient?: QueryClient) => useMutation({
      mutationFn: ({ eventId, feedId }: any) => {
        return HttpSrvc.delete(`${FEED_CONSTANTS.FEEDS_API}/${eventId}/${feedId}`);
      },
      onMutate: (variables) => {
        queryClient?.cancelQueries({ queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId] });
      },
      onSuccess: (data: any, variables: any) => {
        queryClient?.invalidateQueries({ queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId] });
      }
    }),
    LIKE: (queryClient?: QueryClient) => useMutation({
      mutationFn: ({ eventId, feedId, like }: any) => {
        const url = `${FEED_CONSTANTS.FEEDS_API}/${eventId}/${feedId}/like`;
        return HttpSrvc.post(url, { like });
      },
      onSuccess: (response, variables, context) => {
        const listKey = "feeds";
        queryClient?.setQueryData([FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId, variables.feedId], response);
        queryClient && updateInifiniteQueryList(queryClient, [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId], listKey, response);
      }
    })
  },
  COMMENTS: {
    POST: (queryClient?: QueryClient) => useMutation({
      mutationFn: ({ eventId, feedId, comment }: any) => {
        const url = `${FEED_CONSTANTS.FEEDS_API}/${eventId}/${feedId}/comment`;
        return HttpSrvc.post(url, { comment });
      },
      onMutate: (variables) => {
        queryClient?.cancelQueries({ queryKey: [FEED_CONSTANTS.QUERY_KEYS.COMMENTS, variables.eventId, variables.feedId] });
      },
      onSuccess: (response, variables) => {
        const listKey = "feeds";
        queryClient?.invalidateQueries({ queryKey: [FEED_CONSTANTS.QUERY_KEYS.COMMENTS, variables.eventId, variables.feedId] });
        queryClient?.setQueryData([FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId, variables.feedId], response);
        queryClient && updateInifiniteQueryList(queryClient, [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId], listKey, response);
      }
    }),
    DELETE: (queryClient?: QueryClient) => useMutation({
      mutationFn: ({ eventId, feedId, commentId }: any) => {
        const url = `${FEED_CONSTANTS.FEEDS_API}/${eventId}/${feedId}/comment/${commentId}`;
        return HttpSrvc.delete(url);
      },
      onSuccess: (response, variables, context) => {
        queryClient?.invalidateQueries({
          queryKey: [FEED_CONSTANTS.QUERY_KEYS.COMMENTS, variables.eventId, variables.feedId],
          exact: true
        });
        queryClient?.invalidateQueries({
          queryKey: [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId, variables.feedId],
          exact: true
        });;
        const listKey = "feeds";
        queryClient && updateInifiniteQueryList(queryClient, [FEED_CONSTANTS.QUERY_KEYS.FEEDS, variables.eventId], listKey, response);
      }
    }),
  },
};

export const FEED_CONSTANTS = {
  QUERY_KEYS: {
    FEEDS: 'FEEDS',
    COMMENTS: 'COMMENTS'
  },
  FEEDS_API: '/api/feeds'
}