// Utility functions for attendees components
import { Attendee, RSVP_STATUS, ATTENDEE_STATUS } from './types';

export const filterAttendeesByStatus = (
  attendees: Attendee[],
  status: string
): Attendee[] => {
  return (attendees && attendees?.length > 0) ? attendees.filter(attendee => 
    status === attendee.status
  ) : [];
};

export const filterAttendeesBySearch = (
  attendees: Attendee[],
  searchText: string
): Attendee[] => {
  if (!searchText) return attendees;
  const search = searchText.toLowerCase();
  return attendees.filter(attendee => 
    attendee.firstName?.toLowerCase().includes(search) ||
    attendee.lastName?.toLowerCase().includes(search)
  );
};

export const filterAttendeesByTag = (
  attendees: Attendee[],
  tag: string | null
): Attendee[] => {
  if (!tag) return attendees;
  return attendees.filter(attendee => 
    attendee.tags?.includes(tag)
  );
};

export const extractUniqueTags = (attendees: any[]): string[] => {
  const tagSet = new Set<string>();
  attendees.forEach(attendee => {
    attendee?.user?.tags?.forEach((tag: string) => tagSet.add(tag));
  });
  return Array.from(tagSet);
};