import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';

export const QUERIES = {
  GET_EVENT_DOCUMENTS_FOR_USER: (eventId: string, userId: string) => useQuery({
    queryKey: [DOCS_CONSTANTS.QUERY_KEYS.EVENT_DOCUMENTS, eventId, userId],
    queryFn: () => {
      return HttpSrvc.get(`${DOCS_CONSTANTS.DOCS_API}/event/${eventId}/user/${userId}`);
    },
    enabled: !!eventId && !!userId
  })
};

export const MUTATIONS = {
  HOST_DOCUMENT_UPLOAD_FOR_GUEST: (queryClient: QueryClient) => useMutation({
    mutationFn: ({ eventId,
      userId,
      formData }: {
        eventId: string;
        userId: string;
        formData: FormData;
      }) => {
      return HttpSrvc.post(`${DOCS_CONSTANTS.DOCS_API}/event/${eventId}/user/${userId}`, formData);;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [DOCS_CONSTANTS.QUERY_KEYS.EVENT_DOCUMENTS, variables.eventId, variables.userId] });
    }
  })
};

export const DOCS_CONSTANTS = {
  QUERY_KEYS: {
    EVENT_DOCUMENTS: 'EVENT_DOCUMENTS'
  },
  DOCS_API: '/api/documents'
};