import { combineDateTime, isEndTimeAfterStartTime } from './dateTime';

export const getFormFieldValue = (form: HTMLFormElement, fieldName: string): string => {
  const element = form.elements.namedItem(fieldName) as HTMLInputElement | HTMLTextAreaElement;
  return element?.value || '';
};

export const createActivityFormData = (
  form: HTMLFormElement, 
  selectedFile: File | null
): FormData | { error: string } => {
  // Get the date and time values
  const startDate = getFormFieldValue(form, 'startDate');
  const startTime = getFormFieldValue(form, 'startIsoTime');
  const endDate = getFormFieldValue(form, 'endDate');
  const endTime = getFormFieldValue(form, 'endIsoTime');

  // Combine date and time into ISO strings using moment
  // const startDateTime = combineDateTime(startDate, startTime);
  // const endDateTime = combineDateTime(endDate, endTime);

  // Validate that end time is after start time
  if (!isEndTimeAfterStartTime(startTime, endTime)) {
    return { error: 'End time must be after start time' };
  }

  const formData = new FormData();
  formData.append('name', getFormFieldValue(form, 'name'));
  formData.append('startIsoTime', startTime);
  formData.append('endIsoTime', endTime);
  formData.append('description', getFormFieldValue(form, 'description'));
  formData.append('subtitle', getFormFieldValue(form, 'subtitle'));
  formData.append('location', getFormFieldValue(form, 'location'));
  
  if (selectedFile) {
    formData.append('file', selectedFile);
  }
  
  return formData;
};