import React, { useEffect, useState } from 'react';
import { Plus, SquarePlus, X } from 'lucide-react';
import EventFormFields from './EventFormFields';
import { createEventFormData } from '../../../utils/event';
import QuerySrvc from '../../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorStringFromObject } from '../../../services/utils';
import CONSTANTS from "../../../config/constants";

interface AddEventFormProps {
  onClose: () => void;
  submitSuccessCallback?: (response: any) => void;
  submitErrorCallback?: (error: any) => void;
  eventId: string;
}

const InviteAttendeesForm: React.FC<AddEventFormProps> = ({ onClose, submitSuccessCallback, submitErrorCallback, eventId }) => {

  const queryClient = useQueryClient();

  const [invitationCount, setInvitationCount] = useState(2);
  const [invitations, setInvitations] = useState<string[]>(['']);
  const [error, setError] = useState<string | null>(null);

  const createRsvpMutation = QuerySrvc.MUTATIONS.RSVP.CREATE(queryClient);

  useEffect(() => {
    if (createRsvpMutation.status === 'success') {
      submitSuccessCallback && submitSuccessCallback(createRsvpMutation.data);
    } else if (createRsvpMutation.status === 'error') {
      setError(getErrorStringFromObject(createRsvpMutation.error));
      submitErrorCallback && submitErrorCallback(createRsvpMutation.error);
    }
  }, [createRsvpMutation.status]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    const result = getInviteGuestFormData(e.currentTarget as HTMLFormElement);
    handleFormSubmit(result);
  };


  const handleFormSubmit = async (emailIds: string[]) => {
    try {
      const payload = {
        invitees: emailIds,
        eventId,
        selfInvited: false
      };
      createRsvpMutation.mutate(payload);
    } catch (error: any) {
      console.error('Failed to create event:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-brand-darkgray/90 flex items-center justify-center p-4 z-50">
      <div className="relative flex flex-col bg-brand-darkgray border border-brand-fadegray rounded-lg w-full max-w-lg h-[50vh]">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-brand-fadegray">
          <h2 className="text-xl font-semibold text-brand-lightgray">Invite Guests</h2>
          <button
            onClick={onClose}
            className="text-brand-lightgray hover:text-brand-lightgray/80"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="px-4 py-2 m-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        {/* Form */}
        <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden">
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-4 custom-scroll">
            <div className="form-control w-full">

              {
                invitations.map((_, index) => (
                  <label key={index} className="input input-bordered flex items-center gap-2 mt-4">
                    <input type="email"
                      required
                      onChange={(e) => {
                        const newInvitations = [...invitations];
                        newInvitations[index] = e.target.value;
                        setInvitations(newInvitations);
                      }}
                      value={invitations[index]}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace' && invitations.length > 1 && invitations[index] === '') {
                          const newInvitations = [...invitations];
                          newInvitations.splice(index, 1);
                          setInvitations(newInvitations);
                        }

                        if (e.key === 'Enter' && invitations[index] !== '' && CONSTANTS.EMAIL_REGEX.test(invitations[index])) {
                          setInvitations([...invitations, '']);
                        }

                      }}
                      placeholder={`Enter email address ${index + 1}`}
                      name={`guestEmail${index}`}
                      className="grow bg-brand-fadegray/20" />
                    {
                      invitations.length > 1 && <X size={20}
                        onClick={() => {
                          if (invitations.length <= 1) {
                            return;
                          }
                          const newInvitations = [...invitations];
                          newInvitations.splice(index, 1);
                          setInvitations(newInvitations);
                        }} />
                    }
                  </label>


                ))}
              <span className='bg-brand-lightgray mt-4 self-end cursor-pointer text-brand-darkgray p-0 rounded'
                onClick={() => setInvitations([...invitations, ''])}>
                <SquarePlus onClick={() => setInvitations([...invitations, ''])}
                  className='self-end cursor-pointer' />
              </span>

            </div>
          </div>

          {/* Footer Actions */}
          <div className="flex justify-end gap-4 p-4 border-t border-brand-fadegray">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-brand-fadegray text-brand-lightgray rounded-lg hover:bg-brand-fadegray/20"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-brand-lightgray text-brand-darkgray rounded-lg hover:bg-brand-lightgray/90"
            >
              Send Invitation
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const getInviteGuestFormData = (form: HTMLFormElement): string[] => {
  const formData = new FormData(form);
  const values = [];
  for (const v of formData.values()) {
    typeof v === 'string' && values.push(v);
  }
  return values;
}
export default InviteAttendeesForm;