import React, { useState } from 'react';
import { Send } from 'lucide-react';

interface CommentInputProps {
  onSubmit: (content: string) => void;
}

export function CommentInput({ onSubmit }: CommentInputProps) {
  const [content, setContent] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (content.trim()) {
      onSubmit(content);
      setContent('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center gap-2 mt-4">
      <input
        type="text"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Write a comment..."
        className="flex-1 bg-[#141515] rounded-full px-4 py-2 text-sm text-white placeholder-[#6B6C6E] focus:outline-none focus:ring-2 focus:ring-[#93c5fd]"
      />
      <button
        type="submit"
        disabled={!content.trim()}
        className="text-[#93c5fd] hover:text-white disabled:opacity-50"
      >
        <Send className="w-5 h-5" />
      </button>
    </form>
  );
}