import React from 'react';
import { Users } from 'lucide-react';

const EmptyState: React.FC = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center h-full py-12 text-center">
      <Users className="w-12 h-12 text-brand-lightgraybackup mb-4" />
      <h3 className="text-lg font-medium text-brand-lightgray mb-2">
        No Attendees Yet
      </h3>
      <p className="text-sm text-brand-lightgraybackup max-w-sm">
        Share the event with your guests to start building your attendee list
      </p>
    </div>
  );
};

export default EmptyState;