import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import StorageSrvc from '../StorageSrvc';
import { STORAGE_SRVC_KEYS } from '../../config/constants/AppStrings';

export const QUERIES = {
  //todo: FIX ENDPOINT

  GET_EVENT_RSVP: (itineraryId: string) => useQuery({
    queryKey: [RSVP_CONSTANTS.QUERY_KEYS.EVENT_RSVP_STATUS, itineraryId],
    queryFn: () => {
      return HttpSrvc.get(`${RSVP_CONSTANTS.EVENT_RSVP_GET}/${itineraryId}`);
    },
    enabled: !!itineraryId,
    staleTime: 1000 * 30,
    select: (data) => {
      return data.map((item: any) => {
        return {
          ...item,
          checked: false
        }
      });
    }
  }),
  GET_RSVP_STATUS_BY_EVENT_ID: (itineraryId: string) => useQuery({
    queryKey: [RSVP_CONSTANTS.QUERY_KEYS.RSVP_STATUS],
    queryFn: () => {
      return HttpSrvc.get(`${RSVP_CONSTANTS.GET_RSVP_STATUS_BY_EVENT_ID_API}/${itineraryId}`);
    },
    enabled: !!itineraryId
  })

}

export const MUTATIONS = {
  CREATE: (queryClient?: QueryClient) => useMutation({
    mutationFn: (payload: {
      "invitees": string[],
      "eventId": string,
      "selfInvited": boolean
    }) => {
      return HttpSrvc.post(RSVP_CONSTANTS.CREATE_RSVP, payload)
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.EVENT_RSVP_STATUS] });
    }
  }),
  RESPOND_RSVP: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, payload }: any) => {
      return HttpSrvc.put(`${RSVP_CONSTANTS.RESPOND_RSVP_API}/${eventId}`, payload)
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.RSVP_STATUS] });
    },
    onError: (error: any) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.RSVP_STATUS] });
    }
  }),
  SEND_REMINDER: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId }: any) => {
      return HttpSrvc.post(`${RSVP_CONSTANTS.RSVP_REMINDER_API}/${eventId}`, { })
    },
    onSuccess: (response, variables) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.EVENT_RSVP_STATUS, variables.eventId] });
    }
  })
}

export const RSVP_CONSTANTS = {
  QUERY_KEYS: {
    RSVP_STATUS: 'RSVP_STATUS',
    EVENT_RSVP_STATUS: 'EVENT_RSVP_STATUS'
  },
  CREATE_RSVP: '/api/rsvp',
  EVENT_RSVP_GET: `/api/rsvp/event`,
  RESPOND_RSVP_API: `/api/rsvp/respond`,
  RSVP_REMINDER_API: `/api/rsvp/request`,
  GET_RSVP_STATUS_BY_EVENT_ID_API: `/api/rsvp/event/self`
}
