import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import EventFormFields from './EventFormFields';
import { createEventFormData } from '../../../utils/event';
import QuerySrvc from '../../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorStringFromObject } from '../../../services/utils';

interface AddEventFormProps {
  onClose: () => void;
  submitSuccessCallback?: (response: any) => void;
  submitErrorCallback?: (error: any) => void;
}

const AddEventForm: React.FC<AddEventFormProps> = ({ onClose, submitSuccessCallback, submitErrorCallback }) => {

  const queryClient = useQueryClient();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const createEventMutation = QuerySrvc.MUTATIONS.EVENT.CREATE_EVENT(queryClient);

  useEffect(() => {
    if (createEventMutation.status === 'success') {
      submitSuccessCallback && submitSuccessCallback(createEventMutation.data);
    } else if (createEventMutation.status === 'error') {
      setError(getErrorStringFromObject(createEventMutation.error));
      submitErrorCallback && submitErrorCallback(createEventMutation.error);
    }
  }, [createEventMutation.status]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    const result = createEventFormData(e.currentTarget as HTMLFormElement, selectedFile);

    if ('error' in result) {
      setError(result.error);
      return;
    }

    handleFormSubmit(result);
  };


  const handleFormSubmit = async (formData: FormData) => {
    try {
      createEventMutation.mutate(formData);
    } catch (error: any) {
      console.error('Failed to create event:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-brand-darkgray/90 flex items-center justify-center p-4 z-50">
      <div className="relative flex flex-col bg-brand-darkgray border border-brand-fadegray rounded-lg w-full max-w-lg max-h-[90vh]">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-brand-fadegray">
          <h2 className="text-xl font-semibold text-brand-lightgray">Create New Event</h2>
          <button
            onClick={onClose}
            className="text-brand-lightgray hover:text-brand-lightgray/80"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="px-4 py-2 m-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        {/* Form */}
        <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden">
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-4 custom-scroll">
            <EventFormFields onFileChange={setSelectedFile} />
          </div>

          {/* Footer Actions */}
          <div className="flex justify-end gap-4 p-4 border-t border-brand-fadegray">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-brand-fadegray text-brand-lightgray rounded-lg hover:bg-brand-fadegray/20"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-brand-lightgray text-brand-darkgray rounded-lg w-40
                enabled:hover:bg-brand-lightgray/90 disabled:opacity-50 disabled:text-brand-fadegray"
              disabled={createEventMutation.isPending}
            >
              {
                createEventMutation.isPending && <span className="loading loading-bars loading-xs"></span> ||
                <span>
                  Create Event
                </span>
              }

            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEventForm;