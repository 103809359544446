import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Camera } from 'lucide-react';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import { ToastContext } from '../Contexts';
import { EventViewMode } from '../events/EventView';
import { useParams } from 'react-router-dom';
interface DayCardProps {
  day: {
    id: string;
    date: string;
    name: string;
    image?: string;
    subtitle?: string;
    description?: string;
    eventId: string;
  };
  selectedDayId: string | null;
  index: number;
  onElementClick: (day: any) => void;
  scrollToDay: (arg1: any, arg2: number) => void;
  mode: EventViewMode;
}



const DayCard: React.FC<DayCardProps> = ({ day, selectedDayId, index, onElementClick, scrollToDay, mode }) => {

  const queryClient = useQueryClient();

  const updateProgramMutation = QuerySrvc.MUTATIONS.PROGRAM.UPDATE_PROGRAM(queryClient);
  const uploadImageMutation = QuerySrvc.MUTATIONS.PROGRAM.UPLOAD_PROGRAM_IMAGE(queryClient);
  const toast = useContext(ToastContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const { eventId } = useParams<{ eventId: string }>();  
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;
  const isHost = event?.isOwner || event?.isHost;
  
  useEffect(() => {
    if (uploadImageMutation.status === 'success') {
      toast?.showSuccessToast('Image uploaded successfully');
    } else if (uploadImageMutation.status === 'error') {
      toast?.showErrorToast('Failed to upload image');
    }
  }, [uploadImageMutation.status]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, dayId: string) => {
    const file = e.target.files?.[0];
    if (!file || !eventId) return;

    uploadImageMutation.mutate({
      eventId,
      dayId,
      file
    });
    fileInputRef.current && (fileInputRef.current.value = '');
  };

  const handleDragUpload = (e: React.DragEvent<HTMLDivElement>, dayId: string) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files?.[0];
    if (!file || !eventId) return;

    uploadImageMutation.mutate({
      eventId,
      dayId,
      file
    });
  }

  const triggerFileInput = (e: React.MouseEvent, dayId: string) => {
    e.stopPropagation();
    if (dayId === selectedDayId && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateProgram = (update: any) => {
    if (!eventId || !selectedDayId) return;
    updateProgramMutation.mutate({
      eventId,
      dayId: selectedDayId,
      payload: update
    });
  };


  return (
    <div key={index} className={`flex pr-4  py-4 sm:pr-0 sm:py-0 day-card max-h-full
      ${selectedDayId === day.id ? 'bg-gradient-to-t from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0' : ''}          
      `}>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={(e) => {
          selectedDayId && handleImageUpload(e, selectedDayId)
        }
        }
      />
      <div className={`content-center ${selectedDayId === day.id ? 'w-28' : 'w-36'}`}>
        <div className=" flex flex-col py-4 text-center">
          <div className={`${selectedDayId === day.id ? 'text-2xl' : 'text-xl'} text-brand-lightgray font-bold`}>
            {moment(new Date(day.date)).format('ddd')}
          </div>
          <div className={`${selectedDayId === day.id ? 'text-md' : 'text-sm'} text-brand-lightgray`}>
            {moment(new Date(day.date)).format('MMM')}
          </div>
          <div className={`${selectedDayId === day.id ? 'text-2xl' : 'text-xl'} text-brand-lightgray font-bold`}>
            {moment(new Date(day.date)).format('DD')}
          </div>
        </div>
      </div>
      <div
        onDragOver={(e) => {
          e.preventDefault();
          if (!isHost || mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          if (!isHost || mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(false);
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (!isHost || mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(false);
          handleDragUpload(e as any, day.id);
        }}
        onClick={() => { onElementClick(day), scrollToDay(undefined, index) }}
        className={
          `relative justify-between flex flex-col flex-auto border border-t-brand-fadegray 
          ${dragging ? 'border-brand-lightgray' : 'border-l-brand-fadegray border-r-brand-darkgray border-b-brand-darkgray'}
           border-2 rounded text-left ${selectedDayId === day.id ? 'w-72' : 'w-64'}`
        }>
        <div className="relative flex aspect-video  top-0 w-full duration-500">
          <div className="absolute flex flex-col justify-between aspect-video top-0 w-full">
            <pre
              contentEditable={isHost && mode === EventViewMode.MANAGE}
              suppressContentEditableWarning={true}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
              onBlur={(e) => {
                if (!isHost || mode !== EventViewMode.MANAGE) {
                  return;
                }
                const content = e.target.innerText.trim();
                content && content !== day?.name ? updateProgram({ name: content }) : null;
              }}
              className={`flex content-start font-semibold text-xl text-brand-lightgray drop-shadow-contrast-black p-2 z-40 ${isHost && mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
              {
                isHost && mode === EventViewMode.MANAGE && !day.name &&
                <span className='text-brand-lightgray font-sans underline'>
                  Click to Add Title
                </span> ||
                <span>{day.name}</span>
              }
            </pre>
          </div>
          <div className="absolute aspect-video top-0 w-full ">
            {day.image && (
              <img
                src={day.image}
                className={`w-full h-full ${selectedDayId === day.id ? 'opacity-90' : 'opacity-70'} rounded hover:opacity-100
                    object-cover transform hover:scale-105 transition-transform duration-200`}
              />
            )}
            {
              <div className={`absolute top-0 flex w-full h-full ${day.image ? 'justify-end' : 'bg-gradient-to-r from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0'}`}>
                {
                  isHost && mode === EventViewMode.MANAGE && (uploadImageMutation.status === 'pending' && uploadImageMutation.variables?.dayId === day.id &&
                    <div className="w-full h-full bg-gradient-to-r from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
                    </div> ||
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        if (selectedDayId !== day.id) {
                          onElementClick(day),
                            scrollToDay(undefined, index);
                        }
                        triggerFileInput(e, day.id);
                      }}
                      className={`                      
                      ${day.image ? 'flex justify-end items-end pr-4 pb-2' : 'absolute flex flex-col items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  text-brand-lightgray'}
                    `}>
                      <Camera className="w-6 h-6 mb-2 drop-shadow-contrast-black cursor-pointer" />
                      {!day.image && <span className="text-sm cursor-pointer">Add Image</span>}
                    </div>)
                }
              </div>
            }
          </div>
        </div>

        <div className={`relative flex flex-col w-full p-2 overflow-hidden content-between overflow-auto`}>
          <pre
            contentEditable={isHost && mode === EventViewMode.MANAGE}
            suppressContentEditableWarning={true}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            onBlur={(e) => {
              if (!isHost || mode !== EventViewMode.MANAGE) {
                return;
              }
              const content = e.target.innerText.trim();
              content && content !== day?.subtitle ? updateProgram({ subtitle: content }) : null;
            }}
            className={`flex content-end whitespace-pre-line font-normal font-sans text-md text-brand-lightgray drop-shadow-contrast-black ${isHost && mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
            {
              isHost && mode === EventViewMode.MANAGE && !day.subtitle && "Click to Add Subtitle" || day.subtitle
            }
          </pre>
          <div className='divider'></div>
          <pre
            contentEditable={isHost && mode === EventViewMode.MANAGE}
            suppressContentEditableWarning={true}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            onBlur={(e) => {
              if (!isHost || mode !== EventViewMode.MANAGE) {
                return;
              }
              const content = e.target.innerText.trim();
              content && content !== day?.description ? updateProgram({ description: content }) : null;
            }}
            className={`flex-1 whitespace-pre-line hover:overflow-auto custom-scroll ${selectedDayId === day.id ? '' : 'line-clamp-3 '}
            font-normal font-sans text-sm text-brand-lightgray content-end drop-shadow-contrast-black pt-4 ${isHost && mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
            {
              isHost && mode === EventViewMode.MANAGE && !day.description && "Click to Add Description" || day.description
            }
          </pre>
        </div>
      </div>
    </div>
  );
};

export default DayCard;