import React, { useMemo } from 'react';
import { Calendar, Users, MessageSquare, FileText, MessageSquareText } from 'lucide-react';
import { EventViewMode } from './EventView';
import { useParams } from 'react-router-dom';
import QuerySrvc from '../../services/queries/QuerySrvc';

export type TabType = 'itinerary' | 'social' | 'attendees' | 'documents' | 'feedback';

interface EventTabsProps {
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
  fabText?: string;
  fabVisible?: boolean;
  onFabClick?: () => void;
  fabPulse?: boolean;
  mode?: EventViewMode;
}

export const getEventTabs = (isHost: boolean, mode: EventViewMode): {id: TabType; label: string; icon: any}[] => {
  const tabs = [
    { id: 'itinerary' as TabType, label: 'Itinerary', icon: Calendar },
    { id: 'social' as TabType, label: 'Social', icon: MessageSquare },
    { id: 'attendees' as TabType, label: 'Attendees', icon: Users },
    { id: 'documents' as TabType, label: 'Documents', icon: FileText },
    ...(isHost && mode === EventViewMode.MANAGE ? [
      { id: 'feedback' as TabType, label: 'Feedback', icon: MessageSquareText },
    ] : []),
  ];
  
  return tabs;
};

const EventTabs: React.FC<EventTabsProps> = ({ activeTab, onTabChange, fabText, fabVisible, onFabClick, fabPulse, mode }) => {
  
  const { eventId } = useParams<{ eventId: string }>();  
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;
  const isHost = event?.isOwner || event?.isHost;
  
  const tabs = useMemo(() => getEventTabs(isHost, mode!), [isHost, mode]);

  return (
    <div className="flex flex-1 space-x-4 bg-brand-darkgray z-40">
      <div className='flex flex-1'>
        {tabs.map(tab => {
          const Icon = tab.icon;
          return (
            <button
              key={tab.id}
              onClick={() => onTabChange(tab.id)}
              className={`flex items-center px-4 py-2 transition-colors ${activeTab === tab.id
                ? 'text-brand-lightgray border-b'
                : 'text-brand-lightgraybackup hover:text-brand-lightgray hover:bg-brand-lightgray/5'
                }`}
            >
              <Icon className="w-4 h-4 mr-2" />
              <span className="ml-2 bg-brand-fadegray/20 px-2 py-0.5 rounded-full text-sm">
                {tab.label}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default EventTabs;