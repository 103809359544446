import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EventFeeds from './EventFeeds';
import EventTabs, { getEventTabs, TabType } from './EventTabs';
import ItineraryViewer from '../itinerary/ItineraryViewer';
import AttendeesList from './attendees/AttendeesList';
import FloatingActionButton from '../FloatingActionButton';
import InviteAttendeesForm from './form/InviteAttendeesForm';
import { ToastContext } from '../Contexts';
import QuerySrvc from '../../services/queries/QuerySrvc';
import DocumentsView from './DocumentsView';
import ShareEvent from './ShareEvent';
import FeedbackManager from './FeedbackManager';

export interface Event {
  id: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  image: string;
  owner: {
    firstName: string;
    lastName: string;
    emailId: string;
    profilePic: string;
  };
}


export enum EventViewMode {
  VIEW,
  MANAGE
}

const EventView = ({ mode = EventViewMode.VIEW }: { mode?: EventViewMode }) => {
  const { tab, schedule, eventId, subview } = useParams<{ eventId: string, subview: string, tab: string, schedule: string }>();
  const [activeTab, setActiveTab] = useState<TabType>(subview as any);
  const [showInviteAttendeesForm, setShowInviteAttendeesForm] = useState(false);
  const [showShareQRModal, setShowShareQRModal] = useState(false);
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const { data: event, isLoading, error }: any = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId);
  const isHost = event?.isOwner || event?.isHost;

  const onHeaderActionClick = useCallback((buttonId: string) => {
    switch (buttonId) {
      case 'invite':
        setShowInviteAttendeesForm(true);
        break;
      case 'share':
        setShowShareQRModal(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (subview && getEventTabs(isHost, mode).find(tab => tab.id === subview)) {
      setActiveTab(subview as TabType);
    } else if (mode === EventViewMode.MANAGE) {
      navigate(`/manage/${tab}/${schedule}/${eventId}/itinerary`);
    } else {
      navigate(`/events/${eventId}/itinerary`);
    }
  }, [subview, isHost]);

  if (isLoading) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
    </div>
  );

  if (error) {
    let message = "Error loading event";
    if (error.response?.status === 404) {
      message = "Event not found";
    }
    return (
      <div className="flex justify-center items-center min-h-screen text-brand-lightgray">
        {message}
      </div>
    );
  }

  const getEventItineraryLink = () => {
    return `${window.location.origin}/itinerary/${eventId}`;
  }


  const renderTabContent = () => {
    switch (activeTab) {
      case 'itinerary':
        return eventId && <ItineraryViewer key={eventId} itineraryId={eventId} mode={mode} />;
      case 'social':
        return eventId && <EventFeeds eventId={eventId} />;
      case 'attendees':
        return eventId && <AttendeesList eventId={eventId} onHeaderActionClick={onHeaderActionClick} />;
      case 'documents':
        return eventId && <DocumentsView mode={mode} />;
      case 'feedback':
        return mode === EventViewMode.MANAGE && eventId && <FeedbackManager eventId={eventId} /> || null;
      default:
        return null;
    }
  };
  const doNavigateTab = (tabClicked: string) => {
    if (mode === EventViewMode.MANAGE) {
      return navigate(`/manage/${tab}/${schedule}/${eventId}/${tabClicked}`);
    } else {
      return navigate(`/events/${eventId}/${tabClicked}`);
    }
  }

  if (!event) return null;

  return (
    <div className="flex flex-1 flex-col relative bg-brand-darkgray text-brand-lightgray overflow-y-auto">

      <div className={`sticky flex ${mode === EventViewMode.MANAGE ? '' : 'top-0'} z-40 bg-brand-darkgray`}>
        <div className="flex flex-1 px-4">
          <EventTabs
            activeTab={activeTab}
            onTabChange={doNavigateTab}
            fabText='Invite'
            fabVisible={activeTab === 'attendees'}
            onFabClick={() => { setShowInviteAttendeesForm(true) }}
            fabPulse={false}
            mode={mode}
          />
        </div>
      </div>

      {
        showInviteAttendeesForm && <InviteAttendeesForm
          onClose={() => setShowInviteAttendeesForm(false)}
          submitSuccessCallback={(response: any) => {
            setShowInviteAttendeesForm(false);
            toast?.showSuccessToast('Invitations Sent!');
          }}
          submitErrorCallback={(error: any) => { }}
          eventId={eventId!}
        />
      }

      {
        showShareQRModal &&
        <ShareEvent link={getEventItineraryLink()} onOutsideClick={(e) => { setShowShareQRModal(false) }} />
      }

      {/* Tab Content */}
      <div className="flex flex-1 p-0 overflow-y-auto">
        {renderTabContent()}
      </div>
      <FloatingActionButton
        onClick={() => {
          setShowInviteAttendeesForm(true)
        }}
        visible={activeTab === 'attendees'}
        className={`flex bottom-4 right-6  sm:hidden`}
        text={''}
        pulse={false}
      />
    </div>
  );
};

export default EventView;