import React from 'react';
import { Check, X, Clock, MessageSquare, Ban } from 'lucide-react';
import { Attendee, ATTENDEE_STATUS, RSVP_STATUS, RSVP_STATUS_TYPES } from './types';
import { getUserNameOrEmail, getProfilePic } from '../../../services/utils';
import { ATTENDEE_RSVP_STATUS } from './Attendee.Constants';

interface AttendeeCardProps {
  attendee: any;
  onMessageClick?: (attendee: Attendee) => void;
  onProfileClick?: (attendee: Attendee) => void;
  showIcon?: boolean;
  groupStatus: string;
  onCheck?: (attendee: Attendee, groupStatus: string) => void;
  isOwner?: boolean;
  isHost?: boolean;
  isGuest?: boolean;
  currentUser?: any;
  showCheckboxes?: boolean;
}

const AttendeeCard: React.FC<AttendeeCardProps> = ({
  attendee,
  onMessageClick,
  onProfileClick,
  showIcon = false,
  groupStatus,
  onCheck,
  isOwner,
  isHost,
  isGuest,
  currentUser,
  showCheckboxes = true
}) => {
  const profilePic = getProfilePic(attendee?.user?.profilePic);
  const username = getUserNameOrEmail(attendee?.user);
  const getStatusIcon = () => {
    switch (attendee.status) {
      case 'YES':
        return <Check className="w-4 h-4 text-brand-midgreen" />;
      case 'NO':
        return <Ban className="w-4 h-4 text-brand-danger" />;
      default:
        return <Clock className="w-4 h-4 text-brand-gold" />;
    }
  };

  const getColorByStatus = (status: string) => {
    switch (status) {
      case RSVP_STATUS.YES:
        return "text-brand-midgreen";
      case RSVP_STATUS.NO:
        return "text-brand-danger";
      case RSVP_STATUS.EXPIRED:
      case RSVP_STATUS.HOST_REMOVED:
        return "text-brand-danger";
      case RSVP_STATUS.ONBOARD_REQUESTED:
        return "text-brand-gold";
      case RSVP_STATUS.REQUESTED:
        return "text-brand-gold";
      default:
        return "text-brand-lightgray";
    }
  }

  const isCurrentUserCard = currentUser?.guid === attendee?.user?.guid;

  return (
    <div className="flex items-center justify-between p-4 pr-2 border-b border-brand-fadegray">
      <div className="flex items-center space-x-4 flex-1">
        <button
          onClick={() => onProfileClick?.(attendee)}
          className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0"
        >
          {profilePic ? (
            <img
              src={profilePic}
              alt={username}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-brand-fadegray flex items-center justify-center text-brand-lightgray">
              {username?.[0]?.toUpperCase()}
            </div>
          )}
        </button>
        <div className="flex-1">
          <button
            onClick={() => onProfileClick?.(attendee)}
            className="text-left"
          >
            <p className={`${getColorByStatus(attendee.status)}`}>
              {username}

              {
                attendee?.user?.isOwner ? <span className="text-xs bg-brand-fadegray ml-4 px-2 py-1">OWNER</span> :
                  attendee?.user?.isHost ? <span className="text-xs bg-brand-fadegray ml-4 px-2 py-1">HOST </span> : null
              }

              {attendee.status === RSVP_STATUS.HOST_REMOVED && <span className="text-brand-danger text-xs bg-brand-fadegray ml-4 px-2 py-1"> REMOVED </span>}
            </p>
            {attendee?.user?.organisation && (
              <p className="text-sm text-brand-lightgraybackup">
                {attendee?.user?.organisation}
              </p>
            )}
          </button>
        </div>
        {showCheckboxes && (isOwner || isHost) && !isCurrentUserCard && groupStatus === ATTENDEE_RSVP_STATUS.ATTENDING && !attendee?.user?.isOwner && (
          <input
            type="checkbox"
            className="checkbox checkbox-sm checkbox-success border-brand-midgreen"
            checked={attendee.checked}
            onChange={() => onCheck?.(attendee, groupStatus)}
          />
        )}
      </div>
      <div className="flex items-center space-x-4">
        {showIcon && getStatusIcon()}
        {attendee.onboardStatus === 'ONBOARDED' && (
          <button
            onClick={() => onMessageClick?.(attendee)}
            className="text-brand-lightgray hover:text-brand-lightgray/80 transition-colors" >
            <MessageSquare className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};

export default AttendeeCard;