import React, { useEffect, useState } from 'react';
import { FileText, Upload } from 'lucide-react';
import { useParams } from 'react-router-dom';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { RSVP_STATUS } from './attendees/types';
import AttendeeCard from './attendees/AttendeeCard';
import DocumentCard from './DocumentCard';
import { getUserNameOrEmail } from '../../services/utils';
import { EventViewMode } from './EventView';
import { useQueryClient } from '@tanstack/react-query';

interface Document {
  id: string;
  type: string;
  mimeType: string;
  document: {
    original: string;
  };
  otherDocumentType?: string;
  userId: string;
  event: string;
  thirdPartyUploader: {
    guid: string;
    firstName: string;
    lastName: string;
    profilePic: string;
  };
}

const DocumentsView = ({ mode }: any) => {
  const queryClient = useQueryClient();
  const { eventId } = useParams<{ eventId: string }>();
  const { data: rsvpAttendees, isLoading, isFetching: isFetchingRsvpAttendees } = QuerySrvc.QUERIES.RSVP.GET_EVENT_RSVP(eventId!);
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId!) as any;
  const [isUploading, setIsUploading] = useState(false);

  const uploadEventDocsForUserMutation = QuerySrvc.MUTATIONS.DOCS.HOST_DOCUMENT_UPLOAD_FOR_GUEST(queryClient);

  const [selectedAttendeeViewingDocs, setSelectedAttendeeViewingDocs] = useState<any | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const attendingAttendees = rsvpAttendees?.filter((attendee: any) =>
    attendee.status === RSVP_STATUS.YES
  ) || [];

  // Get documents for the selected attendee
  const { data: documents, isLoading: isLoadingDocs } = QuerySrvc.QUERIES.DOCS.GET_EVENT_DOCUMENTS_FOR_USER(
    eventId!,
    selectedAttendeeViewingDocs?.guid || ''
  );
  useEffect(() => {
    if (uploadEventDocsForUserMutation.status === 'success') {
      setIsUploading(false);
    } else if (uploadEventDocsForUserMutation.status === 'error') {
      console.error(uploadEventDocsForUserMutation.error);
      setIsUploading(false);
    }
  }, [uploadEventDocsForUserMutation.status]);

  useEffect(() => {
    if (attendingAttendees.length > 0) {
      setSelectedAttendeeViewingDocs(attendingAttendees[0].user);
    }
  }, [isFetchingRsvpAttendees]);

  const handleAttendeeClick = (attendee: any) => {
    setSelectedAttendeeViewingDocs(attendee.user);
  }


  const handleDrop = (e: React.DragEvent) => {

    const files = Array.from(e.dataTransfer.files);
    const formData = new FormData();
    // @ts-ignore
    // Log file information
    files.forEach(file => {
      if (file) {
        formData.append('file', file);
      }
    });

    if (selectedAttendeeViewingDocs) {
      setIsUploading(true);
      uploadEventDocsForUserMutation.mutate({
        eventId: eventId!,
        userId: selectedAttendeeViewingDocs.guid,
        formData: formData
      });
    }

  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
      </div>
    );
  }


  return (
    <div className="flex flex-1 h-full">
      {/* Left Pane - Attendees List */}
      {(event.isHost || event.isOwner) && <div className="w-80 border-r border-brand-fadegray overflow-y-auto custom-scroll mt-4">
        <div className="divide-y divide-brand-fadegray">
          {attendingAttendees.map((attendee: any) => (
            <div
              key={attendee.id}
              className={`cursor-pointer transition-colors ${selectedAttendeeViewingDocs?.guid === attendee?.user?.guid ? 'bg-brand-fadegray/30' : ''}`}
              onClick={() => handleAttendeeClick(attendee)}
            >
              <AttendeeCard
                attendee={attendee}
                onMessageClick={console.log}
                onProfileClick={console.log}
                showIcon={false}
                groupStatus="ATTENDING"
                onCheck={(e) => { }}
                isOwner={event?.isOwner}
                isHost={event?.isHost}
                isGuest={event?.isGuest}
                currentUser={null}
                showCheckboxes={false}
              />
            </div>
          ))}
        </div>
        {attendingAttendees.length === 0 && (
          <div className="p-4 text-center text-brand-lightgraybackup">
            No attending guests yet
          </div>
        )}
      </div>}

      {/* Right Pane - Documents Area */}
      <div
        className={`flex flex-col flex-1 p-4 overflow-hidden relative ${isDragging ? 'bg-brand-fadegray/20' : ''}`}
        onDragEnter={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setIsDragging(true);

        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setIsDragging(false);

        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setIsDragging(false);
          handleDrop(e);
        }}
      >
        {
          selectedAttendeeViewingDocs && (
            <div className="flex items-center justify-between py-4 mb-4 space-x-4 border-b border-brand-lightgray/50">
              <div className="w-12 h-12 rounded-full overflow-hidden flex shrink-0">
                {selectedAttendeeViewingDocs.profilePic ? (
                  <img
                    src={selectedAttendeeViewingDocs.profilePic}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-brand-fadegray flex items-center justify-center text-brand-lightgray">
                    {getUserNameOrEmail(selectedAttendeeViewingDocs)?.[0]?.toUpperCase()}
                  </div>
                )}
              </div>
              <div className="flex grow flex-row">
                <div className="flex grow flex-col">
                  <p className="flex grow text-lg font-semibold text-brand-lightgray">
                    {selectedAttendeeViewingDocs.firstName} {selectedAttendeeViewingDocs.lastName}
                  </p>
                  <p className="flex grow text-sm text-brand-lightgraybackup">
                    {selectedAttendeeViewingDocs?.organisation}
                  </p>
                </div>
                {mode === EventViewMode.MANAGE && (
                  <button className="dark-btn"
                    onClick={() => {
                      const fileInput = document.createElement('input');
                      fileInput.type = 'file';
                      fileInput.accept = 'image/jpeg,image/jpg,image/png,application/pdf';
                      fileInput.multiple = true;
                      fileInput.onchange = (e: any) => {
                        const files = Array.from(e.target.files);
                        const formData = new FormData();
                        files.forEach((file: any) => {
                          if (file) {
                            formData.append('file', file);
                          }
                        });

                        if (selectedAttendeeViewingDocs) {
                          setIsUploading(true);
                          uploadEventDocsForUserMutation.mutate({
                            eventId: eventId!,
                            userId: selectedAttendeeViewingDocs.guid,
                            formData: formData
                          });
                        }
                      };
                      fileInput.click();
                    }}
                  >
                    <Upload className='w-4 h-4' /> Upload Docs
                  </button>
                )}
              </div>
            </div>
          )}
        {
          isDragging && (
            <div className="flex flex-1 items-center justify-center bg-brand-fadegray/50 backdrop-blur-sm z-50 pointer-events-none" style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
              <div className="flex flex-col items-center gap-4 text-brand-lightgray">
                <Upload className="w-16 h-16" />
                <p className="text-xl font-semibold">Drop files here</p>
              </div>
            </div>
          ) ||
          <>
            {!selectedAttendeeViewingDocs ? (
              <div className="flex flex-col items-center justify-center h-full text-brand-lightgraybackup">
                <FileText className="w-12 h-12 mb-4" />
                <p className="text-lg">Select an attendee to view their documents</p>
              </div>
            ) : isLoadingDocs ? (
              <div className="flex justify-center items-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
              </div>
            ) : documents?.length > 0 ? (
              <div className="w-full h-full overflow-y-auto custom-scroll">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {documents.map((doc: Document) => (
                    <DocumentCard key={doc.id} document={doc} />
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                <FileText className="w-12 h-12 mb-4 text-brand-lightgraybackup" />
                <h2 className="text-xl font-semibold mb-2">No Documents</h2>
                <p className="text-brand-lightgraybackup">
                  No documents found for this attendee
                </p>
              </div>
            )}
          </>
        }
      </div>
    </div>
  );
};

export default DocumentsView;