export interface Attendee {
  checked: boolean;
  id: string;
  firstName: string;
  lastName: string;
  emailId: string;
  profilePic?: string;
  status: RSVP_STATUS_TYPES;
  role: 'HOST' | 'GUEST';
  createdAt: string;
  organisation?: string;
  tags?: string[];
  onboardStatus: 'ONBOARDED' | 'NOT_ONBOARDED';
}

export type RSVP_STATUS_TYPES = "ONBOARD_REQUESTED" | "HOST_APPROVAL_REQUESTED" | "REQUESTED" | "YES" | "NO" | "MAYBE" | "EXPIRED"

export const RSVP_STATUS = {
  ONBOARD_REQUESTED: "ONBOARD_REQUESTED",
  HOST_APPROVAL_REQUESTED: "HOST_APPROVAL_REQUESTED",
  HOST_APPROVAL_DENIED: "HOST_APPROVAL_DENIED",
  REQUESTED: "REQUESTED",
  YES: "YES",
  NO: "NO",
  MAYBE: "MAYBE",
  EXPIRED: "EXPIRED",
  HOST_REMOVED: "HOST_REMOVED",
}

export interface AttendeesResponse {
  count: number;
  attendees: Attendee[];
}

export type AttendeeStatus = {
  label: string;
  value: string;
}

export const ATTENDEE_STATUS = {
  RSVP: { label: 'RSVP', value: 'rsvp' },
  ONBOARDED: { label: 'Onboarded', value: 'ONBOARDED' },
  NOT_ONBOARDED: { label: 'Not Onboarded', value: 'NOT_ONBOARDED' },
  ONBOARD_REQUESTED: { label: 'Onboard Requested', value: 'ONBOARD_REQUESTED' },
  HOST_APPROVAL_REQUESTED: { label: 'Host Approval Requested', value: 'HOST_APPROVAL_REQUESTED' },
  REQUESTED: { label: 'Requested', value: 'REQUESTED' },
  YES: { label: 'Yes', value: 'YES' },
  NO: { label: 'No', value: 'NO' },
  MAYBE: { label: 'Maybe', value: 'MAYBE' },
  EXPIRED: { label: 'Expired', value: 'EXPIRED' },
} as const;