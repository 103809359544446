import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Image, Video, Send } from 'lucide-react';
import { FileUploader } from './FileUploader';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorStringFromObject, getFileAsFormData } from '../../services/utils';
import { ToastContext } from '../Contexts';
import { get } from 'react-hook-form';

export function CreatePost({ eventId }: { eventId: string }) {
  const queryClient = useQueryClient();
  const [content, setContent] = useState('');
  const [isUploaderOpen, setIsUploaderOpen] = useState(false);
  const [uploadType, setUploadType] = useState<'image' | 'video' | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const createFeedMutation = QuerySrvc.MUTATIONS.FEED.FEEDS.POST(queryClient);
  const toast = useContext(ToastContext);

  const handlePost = useCallback(() => {
    let formData = null;
    if (selectedFile) {
      formData = getFileAsFormData(selectedFile);
    } else {
      formData = new FormData();
    }

    formData.append('title', content.trim());
    createFeedMutation.mutate({ formData, eventId: eventId });
  }, [selectedFile, content]);

  useEffect(() => {
    if (createFeedMutation.status === 'success') {
      setContent('');
      setSelectedFile(null);
      setIsUploaderOpen(false);
      setUploadType(null);
      toast?.showSuccessToast('Your post is live!');
    } else if (createFeedMutation.status === 'error') {
      toast?.showErrorToast(getErrorStringFromObject(createFeedMutation.error) || 'Error posting feed');
    }
  }, [createFeedMutation.status]);

  return (
    <div className="bg-[#292a2a] rounded-lg shadow p-4 mb-4 border border-[#6B6C6E]/20">
      <div className="mb-3">
        <textarea
          // disabled={createFeedMutation.isPending}
          className="w-full p-3 bg-[#141515] border border-[#6B6C6E]/20 rounded-lg resize-none text-white placeholder-[#6B6C6E] focus:outline-none focus:ring-2 focus:ring-[#93c5fd]"
          placeholder="What's on your mind?"
          rows={3}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>

      {isUploaderOpen && (
        <FileUploader
          type={uploadType}
          uploadingInProgress={createFeedMutation.isPending}
          onClose={(e) => !createFeedMutation.isPending && setIsUploaderOpen(false)}
          onSelect={(file) => {
            setSelectedFile(file);
          }}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}

      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <button
            onClick={() => {
              setUploadType('image');
              setIsUploaderOpen(true);
            }}
            className="flex items-center gap-2 px-3 py-2 text-white/80 hover:text-[#93c5fd] rounded-lg transition"
          >
            <Image className="w-5 h-5" />
            <span>Photo</span>
          </button>
          <button
            onClick={() => {
              setUploadType('video');
              setIsUploaderOpen(true);
            }}
            className="flex items-center gap-2 px-3 py-2 text-white/80 hover:text-[#93c5fd] rounded-lg transition"
          >
            <Video className="w-5 h-5" />
            <span>Video</span>
          </button>
        </div>
        <div className='flex flex-row gap-4'>
          {
            selectedFile && <button
              onClick={() => { setSelectedFile(null); setContent(''); setIsUploaderOpen(false) }}
              disabled={!selectedFile || createFeedMutation.isPending}
              className={`px-4 py-2 bg-brand-lightgray text-brand-darkgray rounded-lg
             flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed transition`}
            >
              Cancel
            </button>
          }
          <button
            onClick={handlePost}
            disabled={((!content.trim()) && !selectedFile) || createFeedMutation.isPending}
            className={`px-4 py-2 bg-brand-darkgray text-brand-lightgray rounded-lg
             flex items-center gap-2 hover:text-brand-darkgray hover:bg-brand-lightblue/90 disabled:opacity-50 disabled:cursor-not-allowed transition`}
          >
            <Send className="w-4 h-4" />
            Post
          </button>
        </div>
      </div>
      {
        createFeedMutation.isPending && <div className="text-white/80 text-left p-4 mt-4">
          
          Processing Post ... <br/>
          <progress className="progress w-56"></progress>
          </div>
      }
    </div>
  );
}