import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import EventGroup from './EventGroup';
import EventStatusTabs from './EventStatusTabs';
import FloatingActionButton from '../FloatingActionButton';
import AddEventForm from '../events/form/AddEventForm';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastContext } from '../Contexts';
import EventView, { EventViewMode } from '../events/EventView';

interface Event {
  id: string;
  name: string;
  startTime: string;
  endTime: string;
  image: string;
  owner: {
    firstName: string;
    lastName: string;
    emailId: string;
    profilePic: string;
  };
}

type EventScheduleStatus = 'ongoing' | 'upcoming' | 'finished';

const isValidScheduleStatus = (status: string): status is EventScheduleStatus => {
  return status ? ['ongoing', 'upcoming', 'finished'].includes(status) : false;
}

const ManageEventsList = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { tab, schedule, eventId } = params;

  const initialActiveStatus = schedule && isValidScheduleStatus(schedule) ? schedule : 'ongoing';

  const [activeStatus, setActiveStatus] = useState<EventScheduleStatus>(initialActiveStatus);
  const [showAddForm, setShowAddForm] = useState(false);
  const toast = useContext(ToastContext);

  const { data: events = [], isLoading } = QuerySrvc.QUERIES.EVENT.EVENTS_GET();

  useEffect(() => {
    if (schedule && isValidScheduleStatus(schedule)) {
      setActiveStatus(schedule as EventScheduleStatus);
    }
  }, [schedule]);

  const handleEventClick = (event: Event) => {
    navigate(`/manage/events/${activeStatus}/${event.id}`);
  };

  const groupEvents = (events: Event[]) => {
    const now = new Date();
    const groups = {
      upcoming: [] as Event[],
      ongoing: [] as Event[],
      finished: [] as Event[]
    };

    events.forEach(event => {
      const startDate = new Date(event.startTime);
      const endDate = new Date(event.endTime);

      if (startDate > now) {
        groups.upcoming.push(event);
      } else if (endDate < now) {
        groups.finished.push(event);
      } else {
        groups.ongoing.push(event);
      }
    });

    return groups;
  };

  const groupedEvents = groupEvents(events);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
      </div>
    );
  }

  if (tab?.toLowerCase() === 'events' && eventId) {
    return <EventView mode={EventViewMode.MANAGE} />;
  }

  return (
    <div className="flex flex-1 flex-col space-y-8 overflow-hidden">
      <div className="sticky z-40 bg-brand-darkgray">
        <div className="mx-auto px-4 py-1 flex flex-row justify-between">
          <EventStatusTabs
            activeStatus={activeStatus}
            onStatusChange={(status) => {
              // setActiveStatus(status);
              navigate(`/manage/events/${status}`);
            }}
            counts={{
              ongoing: groupedEvents.ongoing.length,
              upcoming: groupedEvents.upcoming.length,
              finished: groupedEvents.finished.length
            }}
            fabVisible={!showAddForm}
            onFabClick={() => setShowAddForm(true)}
            fabPulse={groupedEvents[activeStatus].length === 0}
          />
        </div>
      </div>

      {showAddForm ? (
        <AddEventForm
          onClose={() => setShowAddForm(false)}
          submitSuccessCallback={(response) => {
            setShowAddForm(false);
            toast?.showSuccessToast('Event Created');
          }}
          submitErrorCallback={(error) => { }}
        />
      ) : (
        groupedEvents[activeStatus].length > 0 ? (
          <EventGroup
            events={groupedEvents[activeStatus]}
            onEventClick={handleEventClick}
          />
        ) : (
          <div className="flex flex-1 justify-center items-center text-center text-brand-lightgray py-8">
            You do not have any {activeStatus} events.
          </div>
        )
      )}

      <FloatingActionButton
        onClick={() => setShowAddForm(true)}
        visible={!showAddForm}
        className="flex bottom-4 right-6  sm:hidden"
        text=''
      />
    </div>
  );
};

export default ManageEventsList;