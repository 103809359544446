import { Check, X } from 'lucide-react';
import React, { useRef } from 'react';
import QRCode from 'react-qr-code';

interface ShareEventProps {
  link: string;
  onOutsideClick: (e: React.MouseEvent) => void;
}

const ShareEvent: React.FC<ShareEventProps> = ({ link, onOutsideClick }) => {
  const qrRef: any = useRef();

  const scaleFactor = 4;
  const [isTextCopied, setIsTextCopied] = React.useState(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setIsTextCopied(true);
  };

  const downloadQRCode = () => {
    const svg = qrRef.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    const svgWidth = svg.getAttribute("width") || 256; // Default width
    const svgHeight = svg.getAttribute("height") || 256; // Default height
    canvas.width = svgWidth * scaleFactor;
    canvas.height = svgHeight * scaleFactor;

    ctx?.scale(scaleFactor, scaleFactor);

    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {      
      ctx?.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);

      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    img.src = url;
  };

  return (
    <div className="flex flex-1 absolute inset-0 bg-brand-darkgray/50 flex-col items-center justify-center text-center p-4 z-50"
      onClick={(e) => { e.stopPropagation(); onOutsideClick(e) }} >
      <div className="relative flex flex-col items-center justify-center bg-white p-6 aspect-square rounded shadow-lg"
        onClick={(e) => { e.stopPropagation() }} ref={qrRef}>
        <h4 className="mb-8 text-lg text-brand-darkgray font-bold">Share this Event</h4>
        <QRCode value={link} size={360} className='my-8' />
        <div className="flex items-center mt-8 gap-4">
          {/* <span className="mr-2">{link}</span> */}
          <button
            className="bg-brand-darkgray text-brand-lightgray rounded border-none py-2 px-4 cursor-pointer flex flex-row gap-4"
            onClick={handleCopyToClipboard}
          >
            Copy URL to Clipboard
            {isTextCopied && <Check />}
          </button>
          <button
            className="bg-brand-darkgray text-brand-lightgray rounded border-none py-2 px-4 cursor-pointer flex flex-row gap-4"
            onClick={downloadQRCode}
          >
            Download QR Code
          </button>
        </div>

        <button
          className="absolute top-4 right-4 bg-transparent border-none text-brand-darkgray cursor-pointer"
          onClick={onOutsideClick}
        >
          <X />
        </button>
      </div>

    </div>
  );
};

export default ShareEvent;