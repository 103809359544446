import React, { useState } from 'react';
import { X } from 'lucide-react';

interface ImagePreviewProps {
  file: File;
  onRemove: () => void;
}
 
export function ImagePreview({ file, onRemove }: ImagePreviewProps) {
  const [imageUrl, setImageUrl] = useState<any>(null);

  React.useEffect(() => {
    setImageUrl(URL.createObjectURL(file));
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, []);
  
  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt="Preview"
        className="w-full h-64 object-contain rounded-lg"
      />      
    </div>
  );
}