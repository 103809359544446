import React from 'react';
import { X } from 'lucide-react';

interface AttendeeFiltersProps {
  searchText: string;
  onSearchChange: (text: string) => void;
  selectedTag: string | null;
  onTagSelect: (tag: string | null) => void;
  tags: string[];
  onShowFiltersClick: () => void;
}

const AttendeeFilters: React.FC<AttendeeFiltersProps> = ({
  searchText,
  onSearchChange,
  selectedTag,
  onTagSelect,
  tags,
  onShowFiltersClick
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <input
          type="text"
          value={searchText}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder="Search by name..."
          className="flex-1 bg-brand-darkgray border-b border-brand-fadegray p-2 text-brand-lightgray placeholder:text-brand-lightgraybackup focus:outline-none"
        />
        <button
          onClick={onShowFiltersClick}
          className="text-brand-lightblue hover:text-brand-lightblue/80"
        >
          Filters
        </button>
      </div>

      {selectedTag && (
        <div className="flex items-center gap-2">
          <span className="px-3 py-1 rounded-full bg-brand-fadegray text-brand-lightgray text-sm flex items-center gap-2">
            {selectedTag}
            <button
              onClick={() => onTagSelect(null)}
              className="hover:text-brand-lightgray/80"
            >
              <X className="w-4 h-4" />
            </button>
          </span>
        </div>
      )}
    </div>
  );
};

export default AttendeeFilters;