import React, { useState, useRef, useContext } from 'react';
import { MoreVertical, Trash2 } from 'lucide-react';
import { useClickOutside } from '../hooks/hooks';
import { CurrentUserContext } from '../Contexts';

interface FeedMenuProps {
  onDelete: () => void;
  feed: any
}

export function FeedMenu({ onDelete, feed }: FeedMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useContext(CurrentUserContext);
  
  const isFeedOwnerSameAsCurrentUser = feed?.owner?.guid === currentUser?.guid;

  useClickOutside(menuRef, () => setIsOpen(false));

  if (!isFeedOwnerSameAsCurrentUser) {
    return null;
  }

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-1 rounded-full hover:bg-white/10 transition-colors"
      >
        <MoreVertical className="w-5 h-5 text-white/80" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-[#292a2a] rounded-lg shadow-lg border border-[#6B6C6E]/20 py-1 z-10">
          <button
            onClick={() => {
              onDelete();
              setIsOpen(false);
            }}
            className="w-full px-4 py-2 text-left text-red-500 hover:bg-white/5 flex items-center gap-2"
          >
            <Trash2 className="w-4 h-4" />
            Delete
          </button>
        </div>
      )}
    </div>
  );
}