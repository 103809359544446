import React, { useState } from 'react';

interface VideoPreviewProps {
  file: File;
  onRemove: () => void;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ file, onRemove }) => {

  const [url, setUrl] = useState<any>(null);

  React.useEffect(() => {
    setUrl(URL.createObjectURL(file));
    return () => {
      URL.revokeObjectURL(url);
    };
  }, []);

  return (
    <div className="video-preview">
      <video
        src={url}
        controls
        className="w-full aspect-video h-full object-contain bg-brand-darkgray"
      />
    </div>
  );
};

export default VideoPreview;