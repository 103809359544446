import React from 'react';
import type { Comment } from './types';
import { formatDate } from '../../utils/date';
import moment from 'moment';

interface CommentListProps {
  comments: Comment[];
}

export function CommentList({ comments }: CommentListProps) {
  return (
    <div className="mt-4 space-y-1">
      {comments.map((comment) => (
        <div key={comment.id} className="flex space-x-3 py-3">
          <div className="flex w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
              {
                comment.owner.profilePic && <div className='avatar flex flex-1 btn-circle btn border-0 bg-transparent overflow-hidden'>
                  <div className="absolute top-0 w-full">
                    <img src={comment.owner.profilePic} className="w-full"/>
                  </div>
                </div> ||
                <div className="avatar placeholder">
                  <div className="bg-neutral text-neutral-content w-8 rounded-full">
                    <span className="text-md">{comment.owner.profilePic?.[0]?.toUpperCase() || "CP"}</span>
                  </div>
                </div>
              }
            </div>
      
          <div className="flex-1">
            <div className="bg-[#141515] rounded-lg px-4 py-2">
              <div className="font-medium text-sm text-white">
                {comment.owner.firstName} {comment.owner.lastName}
              </div>
              <p className="text-sm text-white/80">{comment.comment}</p>
            </div>
            <div className="mt-1 text-xs text-[#6B6C6E]">
              {moment(comment.createdAt).fromNow()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}