import React from 'react';
import { X } from 'lucide-react';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  tags: string[];
  selectedTag: string | null;
  onTagSelect: (tag: string) => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  onClose,
  tags,
  selectedTag,
  onTagSelect
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-brand-darkgray/90 z-50 flex items-center justify-center p-4">
      <div className="bg-brand-darkgray border border-brand-fadegray rounded-lg w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-xl font-semibold text-brand-lightgray">Filter by Tags</h2>
          <button
            onClick={onClose}
            className="text-brand-fadegray hover:text-brand-lightgray"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-4 max-h-96 overflow-y-auto custom-scroll">
          {tags.map((tag) => (
            <button
              key={tag}
              onClick={() => {
                onTagSelect(tag);
                onClose();
              }}
              className="w-full flex items-center justify-between p-3 hover:bg-brand-fadegray/10 rounded-lg text-brand-lightgray"
            >
              <span>{tag}</span>
              {selectedTag === tag && (
                <div className="w-4 h-4 rounded-full border-2 border-brand-lightgray flex items-center justify-center">
                  <div className="w-2 h-2 rounded-full bg-brand-lightgray" />
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterModal;