import React, { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import { Calendar } from 'lucide-react';
import { debounce } from "lodash";

interface DateTimePickerProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  minDate?: string;
  maxDate?: string;
  position?: 'top' | 'bottom';
  mode?: 'date' | 'time' | 'datetime';
  disabled?: boolean;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  position = 'bottom',
  mode = 'datetime',
  disabled = false
}) => {

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment.utc(value).seconds(0).milliseconds(0));
  const [timeInput, setTimeInput] = useState(moment.utc(value).seconds(0).milliseconds(0).format('HH:mm'));

  const datePickerRef = useRef<HTMLDivElement>(null);
  const timePickerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedDate(moment.utc(value).seconds(0).milliseconds(0));
    setTimeInput(moment.utc(value).seconds(0).milliseconds(0).format('HH:mm'));
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
      if (timePickerRef.current && !timePickerRef.current.contains(event.target as Node)) {
        setShowTimePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      slots.push(`${hour.toString().padStart(2, '0')}:00`);
      slots.push(`${hour.toString().padStart(2, '0')}:30`);
    }
    return slots;
  };

  const handleDateSelect = (day: moment.Moment) => {
    const newDate = day.hour(selectedDate.hour()).minute(selectedDate.minute());
    setSelectedDate(newDate);
    onChange(newDate.toISOString());
    setShowDatePicker(false);
  };

  const handleTimeSelect = (time: string) => {
    const [hours, minutes] = time.split(':');
    const newDate = selectedDate.clone().hour(parseInt(hours)).minute(parseInt(minutes));
    setSelectedDate(newDate);
    // setTimeInput(time);
    onChange(newDate.toISOString());
    setShowTimePicker(false);
  };

  const debouncedOnChange = useMemo(() => {
    return debounce((newDate: moment.Moment) => {
      onChange(newDate.toISOString());
    }, 950);
  }, [onChange]);

  const updateTime = (newTime: string) => {
    if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(newTime)) {
      const [hours, minutes] = newTime.split(':');
      const newDate = selectedDate.clone().hour(parseInt(hours)).minute(parseInt(minutes));
      setSelectedDate(newDate);
      debouncedOnChange(newDate);
    }
  };

  const handleTimeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.preventDefault();
    const input = e.currentTarget;
    const selectionStart = input.selectionStart || 0;
    let [hours, minutes] = timeInput.split(':').map(num => parseInt(num));

    // Handle left/right arrow keys for navigation
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      const newPosition = e.key === 'ArrowLeft'
        ? (selectionStart <= 3 ? 0 : 3)  // Move to start of hours or minutes
        : (selectionStart < 2 ? 3 : 5);  // Move to start of minutes or end

      const endPosition = newPosition === 0 ? 2 : 5;

      setTimeout(() => {
        input.setSelectionRange(newPosition, endPosition);
      }, 0);
      return;
    }

    // Handle up/down arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      const isHours = selectionStart <= 2;
      const increment = e.key === 'ArrowUp' ? 1 : -1;

      if (isHours) {
        hours = (hours + increment + 24) % 24;
      } else {
        minutes = (minutes + increment + 60) % 60;
      }

      const newTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      setTimeInput(newTime);
      updateTime(newTime);

      // Maintain cursor position
      setTimeout(() => {
        input.setSelectionRange(selectionStart, selectionStart);
      }, 0);
      return;
    }

    // Handle delete key
    if (e.key === 'Delete') {
      if (selectionStart === 2) return; // Prevent deleting colon

      let newTime = timeInput;
      const isHours = selectionStart <= 2;

      if (isHours) {
        newTime = `00:${minutes.toString().padStart(2, '0')}`;
      } else {
        newTime = `${hours.toString().padStart(2, '0')}:00`;
      }

      setTimeInput(newTime);
      updateTime(newTime);

      // Maintain cursor position
      setTimeout(() => {
        input.setSelectionRange(selectionStart, selectionStart);
      }, 0);
      return;
    } onChange

    // Handle number keys
    if (/^[0-9]$/.test(e.key)) {
      let newTime = timeInput;
      const isHours = selectionStart <= 2;

      if (isHours) {
        const currentHour = parseInt(timeInput.slice(0, 2));
        const newHour = selectionStart === 0
          ? parseInt(e.key + timeInput.slice(1, 2))
          : parseInt(timeInput.slice(0, 1) + e.key);

        if (newHour >= 0 && newHour < 24) {
          newTime = `${newHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        } onChange
        const currentMinute = parseInt(timeInput.slice(3));
        const newMinute = selectionStart === 3
          ? parseInt(e.key + timeInput.slice(4))
          : parseInt(timeInput.slice(3, 4) + e.key);

        if (newMinute >= 0 && newMinute < 60) {
          newTime = `${hours.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
        }
      }

      setTimeInput(newTime);
      updateTime(newTime);

      // Move cursor positiononChange
      let newPosition = selectionStart + 1;
      if (newPosition === 2) newPosition = 3;
      if (newPosition === 5) newPosition = 4;
      setTimeout(() => {
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
      return;
    }

    // Handle backspace
    if (e.key === 'Backspace') {
      if (selectionStart === 3) return; // Prevent deleting colon

      let newTime = timeInput;
      const isHours = selectionStart <= 2;

      if (isHours) {
        newTime = `00:${minutes.toString().padStart(2, '0')}`;
      } else {
        newTime = `${hours.toString().padStart(2, '0')}:00`;
      }

      setTimeInput(newTime);
      updateTime(newTime);

      // Maintain cursor position
      setTimeout(() => {
        const newPosition = selectionStart - 1;
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
    onChange
    // Handle enter key
    if (e.key === 'Enter') {
      setShowTimePicker(false);
      return;
    }
  };

  const handleTimeClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (disabled) return;
    const input = e.currentTarget;
    const clickPosition = input.selectionStart || 0;

    // Determine which section (hours/minutes) was clicked
    if (clickPosition <= 2) {
      input.setSelectionRange(0, 2);
    } else {
      input.setSelectionRange(3, 5);
    }

    setShowTimePicker(!showTimePicker);
  };

  const generateCalendarDays = () => {
    const startOfMonth = selectedDate.clone().startOf('month');
    const startDay = startOfMonth.day();
    const daysInMonth = selectedDate.daysInMonth();

    const days = [];
    const minMoment = minDate ? moment(minDate) : null;
    const maxMoment = maxDate ? moment(maxDate) : null;

    // Add empty cells for days before the first of the month
    for (let i = 0; i < startDay; i++) {
      days.push(<td key={`empty-${i}`} className="p-2"></td>);
    }

    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = selectedDate.clone().date(day);
      const isDisabled = (minMoment && currentDate.isBefore(minMoment, 'day')) ||
        (maxMoment && currentDate.isAfter(maxMoment, 'day')) || false;
      const isSelected = currentDate.isSame(selectedDate, 'day');

      days.push(
        <td key={day} className="text-center">
          <button
            type="button"
            onClick={() => !isDisabled && handleDateSelect(currentDate)}
            disabled={isDisabled}
            className={`w-8 h-8 rounded-full ${isSelected
              ? 'bg-brand-lightgray text-brand-darkgray'
              : isDisabled
                ? 'text-brand-lightgraybackup cursor-not-allowed'
                : 'hover:bg-brand-fadegray text-brand-lightgray'
              }`}
          >
            {day}
          </button>
        </td>
      );
    }

    return days;
  };

  const renderWeekRows = () => {
    const days = generateCalendarDays();
    const rows: any[] = [];
    let cells: any[] = [];

    days.forEach((day, i) => {
      if (i > 0 && i % 7 === 0) {
        rows.push(<tr key={i}>{cells}</tr>);
        cells = [];
      }
      cells.push(day);
    });

    if (cells.length > 0) {
      rows.push(<tr key={days.length}>{cells}</tr>);
    }

    return rows;
  };

  const getDropdownPosition = () => {
    return position === 'top' ? 'bottom-full mb-1' : 'top-full mt-1';
  };

  const renderDatePicker = () => (
    <div className="relative" ref={datePickerRef}>
      <input
        ref={dateInputRef}
        type="text"
        value={selectedDate.format('ddd, MMM DD')}
        onClick={() => setShowDatePicker(!showDatePicker)}
        disabled={disabled}
        readOnly
        className={`w-32 p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray ${disabled ? '' : 'cursor-pointer'}`}
      />

      {showDatePicker && (
        <div className={`absolute z-10 p-4 bg-brand-darkgray border border-brand-fadegray rounded-lg shadow-lg ${getDropdownPosition()}`}>
          <div className="flex justify-between items-center mb-4">
            <button
              type="button"
              onClick={() => setSelectedDate(selectedDate.clone().subtract(1, 'month'))}
              className="text-brand-lightgray hover:text-brand-lightgraybackup"
            >
              &lt;
            </button>
            <span className="text-brand-lightgray font-semibold">
              {selectedDate.format('MMMM YYYY')}
            </span>
            <button
              type="button"
              onClick={() => setSelectedDate(selectedDate.clone().add(1, 'month'))}
              className="text-brand-lightgray hover:text-brand-lightgraybackup"
            >
              &gt;
            </button>
          </div>
          <table className="w-full">
            <thead>
              <tr>
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                  <th key={day} className="p-2 text-brand-lightgraybackup text-sm">
                    {day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderWeekRows()}</tbody>
          </table>
        </div>
      )}
    </div>
  );

  const renderTimePicker = () => (
    <div className="relative flex flex-1" ref={timePickerRef}>
      <input
        ref={timeInputRef}
        type="text"
        value={timeInput}
        onChange={(e) => {
          // Since we handle all input through keyDown, 
          // this is just to satisfy React's controlled input requirement
          setTimeInput(timeInput);
        }}
        disabled={disabled}
        onKeyDown={handleTimeKeyDown}
        onClick={handleTimeClick}
        className={`w-24 flex flex-1 p-2 text-md select-all caret-transparent ${disabled ? '' : 'cursor-pointer'} font-mono bg-brand-darkgray/50 border border-brand-fadegray rounded-lg text-brand-lightgray text-center`}
      />

      {showTimePicker && (
        <div className={`absolute z-10 py-2 bg-brand-fadegray border border-brand-fadegray rounded-lg shadow-lg max-h-60 overflow-y-auto custom-scroll ${getDropdownPosition()} w-full`}>
          {generateTimeSlots().map(time => (
            <button
              key={time}
              type="button"
              onClick={() => handleTimeSelect(time)}
              className={`block w-full px-4 py-2 text-center hover:bg-brand-lightgray/20 ${time === timeInput
                ? 'bg-brand-lightgray text-brand-darkgray'
                : 'text-brand-lightgray'
                }`}
            >
              {time}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="relative flex flex-row flex-1">
      {label && (
        <label className="block text-sm font-medium text-brand-lightgray mb-1 ml-1">
          <Calendar className="countdown font-mono w-5 h-5 mr-2" /> {label}
        </label>
      )}
      <div className="flex flex-1 gap-2">
        {(mode === 'datetime' || mode === 'date') && renderDatePicker()}
        {(mode === 'datetime' || mode === 'time') && renderTimePicker()}
      </div>
    </div>
  );
};

export default DateTimePicker;